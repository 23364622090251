import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Formik } from 'formik';
import Button from '../../components/Button/Button';
import CardCheckbox from '../../components/Form/CardCheckbox/CardCheckbox';
import Range from 'rc-slider/lib/Range';

import Wizard from '../../components/Wizard/Wizard';
import Input from '../../components/Form/Input/Input';
import Textarea from '../../components/Form/Textarea/Textarea';
import { Navbar } from '../../components/Navbar/Navbar';

import { createNewProjectRequest } from '../../api/newProject';

import messageSentSvg from '../../landing-page/images/message_sent.svg';

const ROUTES = [
  '/new-project/type',
  '/new-project/timeframe',
  '/new-project/budget',
  '/new-project/final',
  '/new-project/success'
];
const INITIAL_VALUES = {
  projectType: null,
  timeFrame: null,
  budget: [57, 121],
  name: '',
  email: '',
  phone: '',
  companyName: '',
  projectDescription: ''
};

const validation = values => {
  const errors = {};

  if (!values.name) errors.name = 'Name is required';

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Email is invalid';
  }

  if (!values.projectDescription)
    errors.projectDescription = 'Project description is required';

  return errors;

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
};

export const NewProjectWizard = ({ history }) => {
  return (
    <NewProjectWizardStyles>
      <Navbar
        navItems={[{ key: 'home', isBtn: true, value: 'Back Home' }]}
        onNavClick={val => {
          if (val === 'home') {
            history.push('/');
          }
        }}
      />
      <Formik
        initialValues={INITIAL_VALUES}
        validate={validation}
        onSubmit={onSubmitProps => {
          createNewProjectRequest(onSubmitProps)
            .then(() => {
              history.push(ROUTES[4]);
            })
            .catch(err => {});
        }}
      >
        {formikProps => {
          return (
            <Wizard
              routes={ROUTES}
              renderHeading={() => (
                <div
                  style={{
                    opacity: '0.65',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                ></div>
              )}
            >
              {({ currentPage, next, prev }) => (
                <Switch>
                  <Route
                    exact
                    path={['/new-project', ROUTES[0]]}
                    render={() => (
                      <div className="is-new-project-main-container">
                        <div className="is-new-project-header">
                          <h1>Type of project</h1>
                        </div>
                        <div className="is-new-project-main">
                          <div className="is-new-project-checkbox-list">
                            <CardCheckbox
                              onChange={isChecked => {
                                if (isChecked) {
                                  formikProps.setFieldValue(
                                    'projectType',
                                    'New Project'
                                  );
                                }
                              }}
                              label="New Project"
                              checked={
                                formikProps.values.projectType === 'New Project'
                              }
                            />
                            <CardCheckbox
                              onChange={isChecked => {
                                if (isChecked) {
                                  formikProps.setFieldValue(
                                    'projectType',
                                    'Existing Project'
                                  );
                                }
                              }}
                              label="Existing Project"
                              checked={
                                formikProps.values.projectType ===
                                'Existing Project'
                              }
                            />
                          </div>
                        </div>
                        <div className="is-new-project-footer">
                          <Button onClick={next}>
                            <span>Next</span>
                            <i className="fas fa-chevron-right" />
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path={ROUTES[1]}
                    render={() => (
                      <div className="is-new-project-main-container">
                        <div className="is-new-project-header">
                          <h1>Timeframe</h1>
                          <p>
                            How much time do you have to release the product?
                          </p>
                        </div>
                        <div className="is-new-project-main">
                          <div className="is-new-project-checkbox-list">
                            <CardCheckbox
                              onChange={isChecked => {
                                if (isChecked) {
                                  formikProps.setFieldValue(
                                    'timeFrame',
                                    'Not Specified'
                                  );
                                }
                              }}
                              label="Not Specified"
                              checked={
                                formikProps.values.timeFrame === 'Not Specified'
                              }
                            />
                            <CardCheckbox
                              onChange={isChecked => {
                                if (isChecked) {
                                  formikProps.setFieldValue(
                                    'timeFrame',
                                    'Less than 6 months'
                                  );
                                }
                              }}
                              label="Less than 6 months"
                              checked={
                                formikProps.values.timeFrame ===
                                'Less than 6 months'
                              }
                            />
                            <CardCheckbox
                              onChange={isChecked => {
                                if (isChecked) {
                                  formikProps.setFieldValue(
                                    'timeFrame',
                                    'More than 6 months'
                                  );
                                }
                              }}
                              label="More than 6 months"
                              checked={
                                formikProps.values.timeFrame ===
                                'More than 6 months'
                              }
                            />
                          </div>
                        </div>
                        <div className="is-new-project-footer">
                          <Button onClick={prev}>
                            <i className="fas fa-chevron-left" />
                            <span>Previous</span>
                          </Button>

                          <Button onClick={next}>
                            <span>Next</span>
                            <i className="fas fa-chevron-right" />
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path={ROUTES[2]}
                    render={() => (
                      <div className="is-new-project-main-container">
                        <div className="is-new-project-header">
                          <h1>Budget</h1>
                          <p>
                            Knowing your budget will help us to make sure that
                            we can match your expectations.
                          </p>
                        </div>
                        <div className="is-new-project-main">
                          <div className="is-new-project-range">
                            <span>&euro;{formikProps.values.budget[0]} K</span>
                            <Range
                              onChange={r => {
                                formikProps.setFieldValue('budget', r);
                              }}
                              defaultValue={formikProps.values.budget}
                              min={5}
                              max={250}
                            />
                            <span>&euro;{formikProps.values.budget[1]} K</span>
                          </div>
                        </div>
                        <div className="is-new-project-footer">
                          <Button onClick={prev}>
                            <i className="fas fa-chevron-left" />
                            <span>Previous</span>
                          </Button>

                          <Button onClick={next}>
                            <span>Next</span>
                            <i className="fas fa-chevron-right" />
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path={ROUTES[3]}
                    render={() => (
                      <div className="is-new-project-main-container">
                        <div className="is-new-project-header">
                          <h1>Your Project</h1>
                          <p>We’re almost there.</p>
                        </div>
                        <div className="is-new-project-main">
                          <Input
                            label={{ text: 'Full Name' }}
                            type="name"
                            name="name"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.name}
                            status={
                              formikProps.touched.name &&
                              formikProps.errors.name &&
                              'error'
                            }
                            inputDescription={
                              formikProps.touched.name &&
                              formikProps.errors.name
                            }
                          />

                          <Input
                            label={{ text: 'Email' }}
                            type="email"
                            name="email"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.email}
                            status={
                              formikProps.touched.email &&
                              formikProps.errors.email &&
                              'error'
                            }
                            inputDescription={
                              formikProps.touched.email &&
                              formikProps.errors.email
                            }
                          />

                          <Input
                            label={{ text: 'Phone (optional)' }}
                            type="phone"
                            name="phone"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.phone}
                            status={
                              formikProps.touched.phone &&
                              formikProps.errors.phone &&
                              'error'
                            }
                            inputDescription={
                              formikProps.touched.phone &&
                              formikProps.errors.phone
                            }
                          />

                          <Input
                            label={{ text: 'Company Name (OPTIONAL)' }}
                            type="companyName"
                            name="companyName"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.companyName}
                            status={
                              formikProps.touched.companyName &&
                              formikProps.errors.companyName &&
                              'error'
                            }
                            inputDescription={
                              formikProps.touched.companyName &&
                              formikProps.errors.companyName
                            }
                          />

                          <Textarea
                            label={{ text: 'About The Project' }}
                            style={{ height: 'auto' }}
                            rows={3}
                            type="projectDescription"
                            name="projectDescription"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.projectDescription}
                            status={
                              formikProps.touched.projectDescription &&
                              formikProps.errors.projectDescription &&
                              'error'
                            }
                            inputDescription={
                              formikProps.touched.projectDescription &&
                              formikProps.errors.projectDescription
                            }
                          />
                        </div>
                        <div className="is-new-project-footer">
                          <Button onClick={prev}>
                            <i className="fas fa-chevron-left" />
                            <span>Previous</span>
                          </Button>
                          <Button
                            onClick={formikProps.handleSubmit}
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          >
                            <span>Finish</span>
                            <i className="fas fa-chevron-right" />
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path={ROUTES[4]}
                    render={() => {
                      return (
                        <div className="is-new-project-main-container">
                          <div className="is-new-project-header">
                            <h1>Thank you for reaching out</h1>
                            <p>
                              We're excited that you are considering working
                              with us. We will get back to you as soon as
                              possible.
                            </p>
                          </div>
                          <div className="is-new-project-main">
                            <div className="is-new-project-success">
                              <img
                                src={messageSentSvg}
                                alt="message sent graphic"
                              />
                            </div>
                          </div>
                          <div className="is-new-project-footer">
                            <Button
                              style={{ marginTop: '14px' }}
                              onClick={() => history.push('/')}
                            >
                              <span>Home</span>
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Switch>
              )}
            </Wizard>
          );
        }}
      </Formik>
    </NewProjectWizardStyles>
  );
};

const NewProjectWizardStyles = styled.section`
  width: 100vw;
  height: 100%;

  .is-wizard-container {
    background-color: #fbfbfb;
  }

  .is-new-project-main-container {
    margin-top: 28px;
    .is-new-project-header {
      & > h1 {
        position: relative;
        margin-top: 0;
        margin-bottom: 0.85em;
        font-weight: 700;
        letter-spacing: -0.05em;
        color: #171727;
        font-size: 33px;
        line-height: 1.62em;
      }

      & > p {
        display: block;
        margin-top: 0;
        margin-bottom: 1.6rem;
        opacity: 0.8;
      }

      & > h1,
      & > p {
        @media (min-width: ${props => props.theme.devices.sm}px) {
          text-align: center;
        }
      }
    }

    .is-new-project-main {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;

      max-width: 560px;
      width: 100%;
      min-height: 200px;
      padding-bottom: 24px;
      padding-top: 24px;
      margin: auto;

      .is-input-container {
        margin-bottom: 20px;
        .is-input-label {
          color: rgba(0, 0, 0, 0.8);
        }
      }

      .is-input {
        background-color: transparent;
      }

      .is-new-project-checkbox-list {
        width: 100%;
      }

      .is-new-project-range {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        & > *:nth-child(2) {
          flex: 1;
        }

        & > *:first-child {
          margin-right: 20px;
        }

        & > *:last-child {
          margin-left: 20px;
        }

        & > span {
          font-weight: bold;
        }

        .rc-slider-rail,
        .rc-slider-track,
        .rc-slider-step {
          height: 7px;
        }

        .rc-slider-track {
          background-color: black;
        }

        .rc-slider-handle {
          margin-left: -7px;
          margin-top: -7px;
          width: 20px;
          height: 20px;
          border-color: black;
        }
      }
    }
  }

  .is-new-project-success {
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    img {
      width: 250px;

      @media (min-width: ${props => props.theme.devices.md}px) {
        width: 400px;
      }
    }
  }

  .is-new-project-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 120px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: black;
      background: linear-gradient(162deg, black, black);

      i:first-child {
        margin-right: 10px;
      }

      i:last-child {
        margin-left: 10px;
      }

      & > * {
        color: white;
      }
    }
  }
`;
