import {
  AUTH_LOGIN_INIT,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_SIGNOUT,
} from '../actionTypes';

const intialState = {
  authenticated: false,
  user: null,
  loginError: null,
  loginLoading: null,
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_INIT:
      return { ...state, loginLoading: true };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        loginError: null,
        loginLoading: false,
      };
    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        authenticated: false,
        user: null,
        loginError: action.payload,
        loginLoading: false,
      };
    case AUTH_SIGNOUT:
      return intialState;
    default:
      return state;
  }
};

export default reducer;
