import React from 'react';
import { StyledButton } from './Button.style';

const Button = ({ children, className = '', ...props }) => {
  return (
    <StyledButton 
      className={`is-button ${className}`}
      {...props}
    >
      {children}
    </StyledButton>
  )
};

export default Button;