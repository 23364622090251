import { call, put, takeEvery } from 'redux-saga/effects';
import { signOut as signOutAction } from '../auth/actions';
import { signOut as signOutFirebase } from '../../api/auth';
import { AUTH_REQUEST_SIGNOUT } from '../actionTypes';

function* signOut() {
  // sign out from firebase
  try {
    yield call(signOutFirebase);
    // update store
    yield put(signOutAction());
  } catch (e) {
    console.log('there was an error signing out.');
  }
}

export default function* watchSignOut() {
  yield takeEvery(AUTH_REQUEST_SIGNOUT, signOut);
}
