import { combineReducers } from 'redux';
import auth from './auth/reducer';
import appInit from './init/reducer';
import mount from './mount/reducer';

export default combineReducers({
  auth,
  appInit,
  mount
});
