import React, { useState, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import {
  animated,
  useSpring,
  useSprings,
  config,
  useChain,
  useTransition
} from 'react-spring';

import Button from 'components/Button/Button';
import { Logo } from 'components/Icons/Icons';
import {
  Header,
  MobileMenuList,
  BurgerIcon
} from 'components/Navbar/Navbar.style';

export const Navbar = ({ onNavClick, navItems = [] }) => {
  const [isActiveBurgerMenu, setBurgerMenu] = useState(false);
  const headerRef = useRef(null);
  const [isHeaderTop, setIsHeaderTop] = useState(true);
  const [scrollHandler] = useDebouncedCallback(
    () => {
      if (window.scrollY > 70) {
        setIsHeaderTop(false);
      } else {
        setIsHeaderTop(true);
      }
    },
    150,
    { maxWait: 150 }
  );

  // burger menu animation
  const mounted = useRef(null);
  const [burgerIconSpring, animateBurgerIcon] = useSprings(2, index => ({
    transform: `translateY(${index === 1 ? '-4.5px' : '4.5px'}) rotate(0deg)`,
    config: config.stiff
  }));

  // animate the burger icon when toggling
  useEffect(() => {
    if (mounted.current) {
      animateBurgerIcon(i => ({
        transform: isActiveBurgerMenu
          ? `translateY(0px) rotate(${i === 0 ? '' : '-'}45deg)`
          : `translateY(${i === 0 ? '-' : ''}4.5px) rotate(0deg)`
      }));
    }

    mounted.current = true;
  }, [animateBurgerIcon, isActiveBurgerMenu]);

  // scroll listener
  useEffect(() => {
    window.document.addEventListener('scroll', scrollHandler);

    return () => {
      window.document.removeEventListener('scroll', scrollHandler);
    };
  });

  /*
    - Mobile Menu animation
  */
  const mobileNavContainerRef = useRef();
  const mobileNavContainerProps = useSpring({
    ref: mobileNavContainerRef,

    config: { ...config.stiff, clamp: true },

    from: {
      opacity: 1,
      transform: 'translateY(100%)'
    },

    to: {
      opacity: isActiveBurgerMenu ? 1 : 1,
      transform: isActiveBurgerMenu ? 'translateY(0%)' : 'translateY(100%)'
    }
  });

  const mobileNavListRef = useRef();
  const mobileNavListTransition = useTransition(
    isActiveBurgerMenu ? navItems : [],
    navItem => navItem.key,
    {
      trail: 400 / navItems.length,
      unique: true,
      from: { transform: 'translate3d(-7px, 0,0)', opacity: 0 },
      enter: { transform: 'translate3d(0px,0, 0)', opacity: 1 },
      leave: { transform: 'translate3d(-7px, 0,0)', opacity: 0 },
      ref: mobileNavListRef
    }
  );

  useChain(
    isActiveBurgerMenu
      ? [mobileNavContainerRef, mobileNavListRef]
      : [mobileNavListRef, mobileNavContainerRef],
    isActiveBurgerMenu ? [0, 0.1] : [0, 0]
  );

  return (
    <Header
      className="is-header-container"
      ref={headerRef}
      activeMobileMenu={isActiveBurgerMenu}
      isHeaderTop={isHeaderTop}
    >
      {/* Desktop Navbar */}
      <div className="is-header">
        <Link to="/">
          <Logo color={isActiveBurgerMenu ? 'black' : 'black'} />
        </Link>

        <BurgerIcon onClick={() => setBurgerMenu(!isActiveBurgerMenu)}>
          {burgerIconSpring.map((props, i) => {
            return <animated.span key={i} style={props} />;
          })}
        </BurgerIcon>

        <ul>
          {navItems.map(({ key, value, isBtn }) => (
            <li
              key={key}
              onClick={() => {
                onNavClick(key);
                setBurgerMenu(false);
              }}
            >
              {!isBtn && value}
              {isBtn && <Button>{value}</Button>}
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile Navbar */}
      <MobileMenuList style={mobileNavContainerProps}>
        <ul>
          {mobileNavListTransition.map(({ item, key, props }) => (
            <animated.li
              onClick={() => {
                onNavClick(item.key);
                setBurgerMenu(false);
              }}
              key={key}
              style={props}
            >
              {!item.isBtn && item.value}
              {item.isBtn && <Button>{item.value}</Button>}
            </animated.li>
          ))}
        </ul>
      </MobileMenuList>
    </Header>
  );
};
