import styled, { css } from 'styled-components';
import Select from 'react-select'

export const SelectWrapper = styled(Select)`
  & > .is-select__control {
    width: 100%;
    height: 100%;

    padding: 2px 0;
        
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.status === 'error' ? props.theme.errorColor : props.theme.inputBorderColor};
    border-radius: ${(props) => props.theme.inputsBorderRadius};

    background-color: ${(props) => props.theme.white};

    color: ${(props) => props.theme.secondaryFontColor};
    font-weight: 600;
    font-size: ${(props) => props.theme.inputDefaultFontSize};
    outline: none;
    box-shadow:${props => props.theme.inputBoxShadow};

    .is-select__indicator {
      svg {
        fill: ${props => props.theme.inputBorderColor};
      }
    }


    &:hover {
      border-color: ${(props) => props.theme.primaryColor};  

      .is-select__indicator {
        color: ${(props) => props.theme.primaryColor}; 

        svg {
          fill: ${(props) => props.theme.primaryColor}; 
        } 
      }

      .is-select__indicator-separator {
        background-color: ${(props) => props.theme.primaryColor};
      }
    }

    .is-select__placeholder {
      color: ${(props) => props.theme.inputPlaceholderColor};
    }

    .is-select__single-value {
      color: ${(props) => props.theme.secondaryFontColor};
    }
  }

  .is-select__control--is-focused {
    border-color: ${(props) => props.theme.primaryColor}; 

    .is-select__indicator {
      color: ${(props) => props.theme.primaryColor};  

      svg {
        fill: ${(props) => props.theme.primaryColor};  
      }
    }

    .is-select__indicator-separator {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }

  .is-select__menu {
    margin: 0;

    color: ${(props) => props.theme.secondaryFontColor};

    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.inputBorderColor};
    border-radius: ${(props) => props.theme.inputsBorderRadius};
    border-top: 0;
    
    font-weight: 600;
    font-size: ${(props) => props.theme.inputDefaultFontSize};
    outline: none;
    box-shadow:${props => props.theme.inputBoxShadow};

    .is-select__menu-list {
      padding: 0;
    }

    .is-select__option {}

    .is-select__option--is-focused {
      background-color: ${(props) => props.theme.grey};
    }

    .is-select__option--is-selected {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }
`;

export const InputDescriptionText = styled.small`
  position: absolute;
  top: 100%;
  margin-top: 0.5em;

  color: ${(props) => props.theme.secondaryFontColor};
  font-weight: 600;
  font-size: ${(props) => props.theme.inputSmallFontSize};

  width: 100%;
  white-space: normal;
  word-break: break-all;


  ${statusDescriptionStyle}
`;

function statusDescriptionStyle(props) {
  if (props.status === 'error') {
    return css`
      color: ${props => (
        props.theme.errorColor
      )} !important;
    `;
  }

  if (props.status === 'success') {
    return css`
      color: ${props => (
        props.theme.successColor
      )} !important;
    `;
  }
}