const colors = {
  white: '#ffffff',
  black: '#000000',
  grey: '#CFD9EC',

  // primary: '#5F90F3',
  // primary: '#2F3544',
  primary: '#000000',
  secondary: '#F1648E',
  success: '#A2DEBC',
  error: '#F1648E',

  fontPrimary: 'black',
  fontSecondary: '#93A0B9',

  // boxShadow: 'RGBA(223, 225, 242, 1)',
  btnBoxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
  boxShadow: 'transparent'
};

const sizes = {
  defaultFont: '0.8em',
  smallFont: '0.7em',
  borderRadius: '6px'
  // borderRadius: '0px',
};

export default {
  // colors
  primaryColor: colors.primary,
  secondaryColor: colors.secondary,
  successColor: colors.success,
  errorColor: colors.error,
  linkColor: colors.primary,

  inputOutlineDefault: colors.primary,
  inputOutlineSuccess: colors.success,
  inputOutlineError: colors.error,
  // inputBorderColor: 'transparent',
  inputBorderColor: colors.grey,
  inputPlaceholderColor: colors.grey,

  primaryFontColor: colors.fontPrimary,
  secondaryFontColor: colors.fontSecondary,
  grey: colors.grey,
  white: colors.white,
  boxShadowColor: colors.boxShadow,

  inputBoxShadow: `0 0 7px ${colors.boxShadow}`,

  defaultBorderRadius: sizes.borderRadius,

  toggleBorderRadius: sizes.borderRadius,

  inputsBorderRadius: sizes.borderRadius,
  inputDefaultFontSize: sizes.defaultFont,
  inputSmallFontSize: sizes.smallFont,

  buttonBorderRadius: sizes.borderRadius,
  buttonDefaultFontSize: sizes.defaultFont,
  buttonSmallFontSize: sizes.smallFont,

  btnBoxShadow: colors.btnBoxShadow,

  letterSpacing: '1px',

  devices: {
    sm: 575,
    md: 768,
    lg: 991,
    xl: 1199
  }
};
