import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import firebase from '../api/firebaseConfig';

import rootReducer from './rootReducer';
import rootSaga from './rootSagas';

import { loginSuccess, signOut } from './auth/actions';
import { initApp } from './init/actions';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

// setup app observer
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    store.dispatch(loginSuccess(user));
    // User is signed in.
  } else {
    // No user is signed in.
    store.dispatch(signOut());
  }
  // init app if not initilzied yet
  if (!store.getState().appInit) store.dispatch(initApp());

  console.log(store.getState());
});

// setInterval(() => void console.log(store.getState()), 1000);

export default store;
