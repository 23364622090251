import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyCb3LcfDFtzIVGCpkzXieA-_cxOR_DpcCg',
  projectId: 'jobspaas',
  authDomain: 'jobspaas.firebaseapp.com',
  databaseURL: 'https://jobspaas.firebaseio.com',
  storageBucket: 'jobspass.appspot.com',
};

// init firebase
firebase.initializeApp(config);

export const db = firebase.firestore();

export default firebase;

window.firebase = firebase;
