import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';

import { Input } from '../../../../components/Form/Form';
import Button from '../../../../components/Button/Button';
import { signUp } from '../../../../api/auth/index';

const formInitialValues = {
  email: '',
  password: '',
  name: '',
};

const validateLogin = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

const SignUpForm = ({ history }) => {
  return (
    <Formik
      initialValues={formInitialValues}
      validate={validateLogin}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        signUp({ email: values.email, password: values.password })
          .then(() => {
            setSubmitting(false);
            history.push('/');
          })
          .catch(err => {
            if (err.code === 'auth/weak-password') {
              setFieldError('password', err.message);
            }

            if (
              err.code === 'auth/email-already-in-use' ||
              err.code === 'auth/invalid-email'
            ) {
              setFieldError('email', err.message);
            }

            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        ...formikProps
      }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Input
              type="name"
              name="name"
              placeholder="Your Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              status={touched.name && errors.name && 'error'}
              inputDescription={touched.name && errors.name}
            />

            <br />

            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              status={touched.email && errors.email && 'error'}
              inputDescription={touched.email && errors.email}
            />

            <br />

            <Input
              autoComplete="new-password"
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              status={touched.password && errors.password && 'error'}
              inputDescription={touched.password && errors.password}
            />

            <br />
            <br />

            <Button style={{ padding: '1.4em' }} type="submit">
              SIGN UP
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default withRouter(SignUpForm);
