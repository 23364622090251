import React, { useState } from 'react';
import { Formik } from 'formik';

import { Input } from '../../../../components/Form/Form';
import Button from '../../../../components/Button/Button';

import { resetPassword } from '../../../../api/auth/index';

const formInitialValues = {
  email: '',
};

const validateLogin = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is required';
  }

  return errors;
};

const ResetPasswordForm = ({ onSubmit }) => {
  const [emailResetSent, setEmailreset] = useState(false);

  if (emailResetSent) {
    return (
      <React.Fragment>
        <h2
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '1em',
            fontSize: '30px',
          }}
        >
          Password reset link has been sent.
        </h2>
        <p
          style={{
            textAlign: 'center',
            marginBottom: '2em',
            fontSize: '0.9em',
            opacity: '0.65',
            lineHeight: '1.4',
          }}
        >
          Please check your e-mail in order to continue
        </p>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h2
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '1em',
          fontSize: '30px',
        }}
      >
        Forgot password?
      </h2>
      <p
        style={{
          textAlign: 'center',
          marginBottom: '2em',
          fontSize: '0.9em',
          opacity: '0.65',
          lineHeight: '1.4',
        }}
      >
        Enter the email you’ve registered with. We’ll send you the instructions
        there.
      </p>
      <Formik
        initialValues={formInitialValues}
        validate={validateLogin}
        onSubmit={({ email }, { setSubmitting }) => {
          resetPassword({ email })
            .then(() => void setEmailreset(true))
            .catch(() => void setEmailreset(true));

          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          ...formikProps
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Input
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                status={touched.email && errors.email && 'error'}
                inputDescription={touched.email && errors.email}
              />
              <br />
              <br />

              <Button style={{ padding: '1.4em' }} type="submit">
                REQUEST PASSWORD RESET
              </Button>
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default ResetPasswordForm;
