import FormCheckbox from './Checkbox/Checkbox';
import FormInput from './Input/Input';
import FormLabel from './Label/Label';
import FormRadio from './Radio/Radio';
import FormSelect from './Select/Select';
import FormSwitch from './Switch/Switch';
import FormTextarea from './Textarea/Textarea';

export const Checkbox = FormCheckbox;
export const Input = FormInput;
export const Label = FormLabel;
export const Radio = FormRadio;
export const Select = FormSelect;
export const Switch = FormSwitch;
export const Textarea = FormTextarea;

export default {
  Checkbox,
  Input,
  Label,
  Radio,
  Select,
  Switch,
  Textarea,
};