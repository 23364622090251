import React from 'react';

export const Logo = ({ color = 'black' }) => {
  return (
    <svg
      width="64px"
      height="96px"
      viewBox="0 0 64 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>BinaryLeap Logo</title>
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop-HD" transform="translate(-59.000000, -198.000000)">
          <g id="Group-2" transform="translate(59.000000, 159.000000)">
            <g
              id="BinaryLeap-Logo-Copy"
              transform="translate(0.000000, 39.000000)"
            >
              <g id="Group">
                <rect
                  id="Rectangle-2"
                  fill={color}
                  opacity="0.9"
                  x="0"
                  y="0"
                  width="26.6666667"
                  height="96"
                />
                <circle
                  id="Oval"
                  fillOpacity="0.87"
                  fill={color}
                  cx="37.3333333"
                  cy="26.6666667"
                  r="26.6666667"
                />
                <circle
                  id="Oval-Copy"
                  fillOpacity="0.87"
                  fill={color}
                  cx="37.3333333"
                  cy="69.3333333"
                  r="26.6666667"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
