import React from 'react';
import { Row, Col } from '../../../components/Layout/Layout';

const AuthenticationLayout = ({ children }) => {
  return (
    <Row flexDirection="column" >
      <Col flex={1}>
        <Row>
          <Col md={0} lg={8} xl={10}
            style={{
              background: 'linear-gradient(RGBA(47, 53, 67, 0.0), RGBA(47, 53, 67, 0.0)), url(https://images.unsplash.com/photo-1483959651481-dc75b89291f1?ixlib=rb-1.2.1&auto=format&fit=crop&w=901&q=80) center center no-repeat',
              backgroundSize: 'cover',
            }}
          >
          </Col>
          <Col md={24} lg={16} xl={14} style={{background: 'white'}}>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthenticationLayout;