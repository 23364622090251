/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';

const WizardStyles = styled.section`
  position: relative;
  width: 100%;
  min-height: 100%;

  padding: 24px;
  padding-top: 48px;
  background-color: #fff;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  .is-progress-bar {
    position: fixed;
    z-index: 1;
    top: 68px;
    left: 0;
    height: 7px;
    margin-bottom: 20px;
    background: black;
    background: linear-gradient(162deg, black, black);
    transition: all 0.3s ease;

    span {
      position: absolute;
      top: calc(100% + 4px);
      right: 0px;
      font-style: normal;
      font-size: 13px;
      color: black;
      opacity: 0.5;
    }
  }

  .is-wizard-heading {
    margin-bottom: 36px;
  }

  .is-wizard-main {
    flex: 1;
  }
`;

export const Wizard = ({
  children,
  routes,
  history: { push },
  location: { pathname },
  renderHeading = null,
  ...props
}) => {
  const [currentPage, setPage] = useState(() => {
    const pageIndex = routes.indexOf(pathname);
    return pageIndex !== -1 ? pageIndex : 0;
  });
  const prevPage = useRef(null);

  const next = () => {
    const newPage = currentPage + 1;
    prevPage.current = currentPage;
    setPage(newPage);
    push(routes[newPage]);
  }; // end next

  const prev = () => {
    const newPage = currentPage - 1;
    prevPage.current = currentPage;
    setPage(newPage);
    push(routes[newPage]);
  }; // end prev

  useEffect(() => {
    const pageIndex = routes.indexOf(pathname);
    setPage(pageIndex !== -1 ? pageIndex : 0);
  }, [pathname]);

  const mainTransition = useTransition(currentPage, p => p, {
    from: {
      opacity: 0,
      transform: `translate3d(${
        prevPage.current > currentPage ? '-' : ''
      }25px,0,0)`
    },

    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },

    leave: {
      display: 'none'
    }
  });

  return (
    <WizardStyles className="is-wizard-container" {...props}>
      <div
        className="is-progress-bar"
        style={{ width: `${((currentPage + 1) / routes.length) * 100}%` }}
      >
        <span style={{ right: currentPage + 1 === routes.length ? '7px' : 0 }}>
          {currentPage + 1}/{routes.length}
        </span>
      </div>

      {renderHeading && (
        <div className="is-wizard-heading">{renderHeading()}</div>
      )}

      {mainTransition.map(({ item, props, key }) => (
        <animated.div key={key} style={{ ...props }} className="is-wizard-main">
          {children({ currentPage, next, prev })}
        </animated.div>
      ))}
    </WizardStyles>
  );
};

export default withRouter(Wizard);
