import styled, { css } from 'styled-components';

// text that is used as the form input's title.
export const InputTitle = styled.span`
  margin-left: 12px;
  
  color: ${(props) => props.theme.secondaryFontColor};
  font-size: ${(props) => props.theme.inputSmallFontSize};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacing};
`;



/*
-------------------------------------------------------------
- TOGGLE STYLES: Checkbox, Radio & Switch
-------------------------------------------------------------
*/

// ToggleCheckmark used by the radio, checkbox and switch components.
export const ToggleCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;


// select or radios inputs outer container
export const ToggleContainer = styled.div`
  position: relative;

  width: 24px;
  height: 24px;
  
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.inputBorderColor};
  box-shadow: ${props => props.theme.inputBoxShadow};
  border-radius: ${props => props.theme.toggleBorderRadius};

  outline: none !important;

  transition: 0.35s;
`;


// the actual html input for select, radio and switch
export const ToggleHiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  transform: translateX(-9999px);
  visibility: visible !important;
  opacity: 0 !important;

  &:checked ~ ${ToggleContainer} {
    background-color: ${props => props.theme.primaryColor};
    border-color: ${props => props.theme.primaryColor};
  }

  &:checked ~ ${InputTitle} {
    color: ${(props) => props.theme.primaryFontColor};
  }

  &:focus ~ ${ToggleContainer} {
    border: 1px solid ${props => props.theme.primaryColor};
  }

  &:focus:checked ~ ${ToggleContainer} {
    opacity: 0.8;
  }

  &:checked ~ ${ToggleContainer} > ${ToggleCheckmark}:after {
    display: block;
  }
`;


// the outer label that holds the whole form input
export const ToggleWrapper = styled.label`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  position: relative;

  margin: 0;
  padding: 0;

  cursor: pointer;

  &:hover > ${InputTitle} {
    opacity: ${props => !props.disabled && 0.8};
  }

  &:hover > ${ToggleContainer} {
    border: 1px solid ${props => !props.disabled ? props.theme.primaryColor : 'transparent'};
  }

  &:hover > ${ToggleHiddenInput}:checked ~ ${ToggleContainer} {
    opacity: ${props => !props.disabled && 0.8};
  }

  ${props => props.disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;