import styled, { css } from 'styled-components';

export const Col = styled.div`
  order: ${props => (props.order ? props.order : 0)};
  flex-grow: ${props => (props.flexGrow ? props.flexGrow : 0)};
  flex-grow: ${props => (props.flexGrow ? props.flexGrow : 0)};
  flex-shrink: ${props => (props.flexShrink ? props.flexShrink : 1)};
  flex-basis: ${props => (props.flexBasis ? props.flexBasis : 'auto')};
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'auto')};
  ${flex}
  ${mediaQueries}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'wrap')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'stretch')};

  /* margin: ${props => (props.gutter ? '-' + props.gutter : 0)}; */

  height: 100%;
  width: 100%;

  & > ${Col} {
    padding: ${props => (props.gutter ? props.gutter : 0)};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

function flex(props) {
  if (props.flex) {
    return css`
      flex: ${props.flex} !important;
    `;
  }
} // end flex

function mediaQueries(props) {
  const COL_MAX_COUNT = 24;
  const sm = props.sm || props.md || props.lg || props.xl;
  const md = props.md || props.lg || props.xl || props.sm;
  const lg = props.lg || props.xl || props.md || props.sm;
  const xl = props.xl || props.lg || props.md || props.sm;

  return css`
    @media (min-width: ${props => props.theme.devices.xl}px) {
      width: ${(xl / COL_MAX_COUNT) * 100}% !important;
      margin: ${getMargin('xl')};
      ${() => {
        if (props.xl === 0) {
          return css`
            display: none;
          `;
        }
      }}
    }

    @media (max-width: ${props => props.theme.devices.xl}px) {
      width: ${(xl / COL_MAX_COUNT) * 100}% !important;
      margin: ${getMargin('xl')};
      ${() => {
        if (props.xl === 0) {
          return css`
            display: none;
          `;
        }
      }}
    }

    @media (max-width: ${props => props.theme.devices.lg}px) {
      width: ${(lg / COL_MAX_COUNT) * 100}% !important;
      margin: ${getMargin('lg')};
      ${() => {
        if (props.lg === 0) {
          return css`
            display: none;
          `;
        }
      }}
    }

    @media (max-width: ${props => props.theme.devices.md}px) {
      width: ${(md / COL_MAX_COUNT) * 100}% !important;
      margin: ${getMargin('md')};
      ${() => {
        if (props.md === 0) {
          return css`
            display: none;
          `;
        }
      }}
    }

    @media (max-width: ${props => props.theme.devices.sm}px) {
      width: ${(sm / COL_MAX_COUNT) * 100}% !important;
      margin: ${getMargin('sm')};
      ${() => {
        if (props.sm === 0) {
          return css`
            display: none;
          `;
        }
      }}
    }
  `;

  function getMargin(deviceWidth) {
    return `${((props.margin &&
      props.margin[deviceWidth] &&
      props.margin[deviceWidth].top) ||
      '0') +
      ' ' +
      ((props.margin &&
        props.margin[deviceWidth] &&
        props.margin[deviceWidth].right) ||
        '0') +
      ' ' +
      ((props.margin &&
        props.margin[deviceWidth] &&
        props.margin[deviceWidth].bottom) ||
        '0') +
      ' ' +
      ((props.margin &&
        props.margin[deviceWidth] &&
        props.margin[deviceWidth].left) ||
        '0')}`;
  } // end getMargin
} // end span
