import React, { useState, useEffect } from 'react';
import { handleVerifyEmail } from '../../../../api/auth/index';

const CHECKING = 'CHECKING';
const VERIFIED = 'VERIFIED';
const ERROR = 'ERROR';

const VerifyEmail = () => {
  const [emailVerifed, setEmailVerified] = useState(CHECKING);

  useEffect(() => {
    const url = new URL(window.location.href);
    const actionCode = url.searchParams.get('oobCode');

    handleVerifyEmail({ actionCode })
      .then(res => {
        setEmailVerified(VERIFIED);
      })
      .catch(err => {
        setEmailVerified(ERROR);
      });
  }, []);

  return (
    <div>
      {emailVerifed === CHECKING && (
        <h3 style={{ fontWeight: '400', textAlign: 'center' }}>
          Please wait...
        </h3>
      )}
      {emailVerifed === VERIFIED && (
        <h3 style={{ fontWeight: '400', textAlign: 'center' }}>
          Your email has been verified
        </h3>
      )}
      {emailVerifed === ERROR && (
        <h3 style={{ fontWeight: '400', textAlign: 'center' }}>
          This link has expired.
        </h3>
      )}
    </div>
  );
};

export default VerifyEmail;
