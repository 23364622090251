import React from 'react';
import { Formik } from 'formik';
import { Input } from 'components/Form/Form';
import Button from 'components/Button/Button';
import { Container, Row, Col } from 'components/Layout/Layout';

export const GeneralSettings = () => {
  return (
    <Formik
      initialValues={{}}
      validate={() => {}}
      onSubmit={({ email, password }, { setSubmitting, setStatus }) => {}}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        ...formikProps
      }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Container>
              <Row>
                <Col>
                  <h3>General Settings</h3>
                </Col>
              </Row>

              <br />

              <Row gutter="12px">
                <Col flex={1}>
                  <Input
                    label={{
                      text: 'First Name',
                    }}
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    status={touched.firstName && errors.firstName && 'error'}
                    inputDescription={touched.firstName && errors.firstName}
                    required
                  />
                </Col>
                <Col flex={1}>
                  <Input
                    label={{
                      text: 'Last Name',
                    }}
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    status={touched.lastName && errors.lastName && 'error'}
                    inputDescription={touched.lastName && errors.lastName}
                    required
                  />
                </Col>
              </Row>
              <br />

              <Row justifyContent="flex-end">
                <Col>
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};
