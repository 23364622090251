import styled from 'styled-components';

export const SidenavWrapper = styled.aside`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  & > * {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active-link {
      color: ${props => props.theme.linkColor};
      fill: ${props => props.theme.linkColor};
    }
  }
`;
