import styled from 'styled-components';

const PADDINGS = {
  MOBILE: { LEFT: '30px', RIGHT: '30px', TOP: '3em', BOTTOM: '2em' },
  TABLET: { LEFT: '4em', RIGHT: '4em', TOP: '3em', BOTTOM: '2em' },
  DESKTOP: { LEFT: '4em', RIGHT: '4em', TOP: '3em', BOTTOM: '2em' }
};

export const LandingPageSection = styled.section`
  background-color: white;
  min-height: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const Jumbotron = styled.section`
  position: relative;
  padding-top: 80px;
  padding-bottom: 140px;
  padding-left: ${PADDINGS.MOBILE.LEFT};
  padding-right: ${PADDINGS.MOBILE.RIGHT};
  background-color: #fbfbfb;

  @media (min-width: ${(props) => props.theme.devices.md}px) {
    padding-top: 124px;
  }

  div.jumbo-content {
    position: relative;
    z-index: 1;

    h1 {
      color: black;
      font-size: 10vw;
      font-weight: 100;
      line-height: 1;
      margin-bottom: 20px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      text-align: left;
      margin-top: 4vh;
      letter-spacing: 0.02em;
    }

    p {
      line-height: 1.4;
      font-weight: 100;
      font-size: 5vw;
      letter-spacing: 0.02em;
      color: black;
      padding-bottom: 24px;
    }
  }

  button {
    border: 2px solid black;

    &:hover {
      background: black;
      color: white;
    }
  }

  img {
    position: absolute;
    bottom: 25px;
    right: 50%;
    transform: translateX(50%);
    width: 84vw;
  }

  @media (min-width: ${(props) => props.theme.devices.sm}px) {
    div.jumbo-content {
      h1 {
        font-size: 6vw;
      }

      p {
        font-size: 3vw;
      }

      button {
      }
    }

    img {
      width: 60vw;
    }
  }

  @media (min-width: ${(props) => props.theme.devices.md}px) {
    padding-left: ${PADDINGS.MOBILE.LEFT};
    padding-right: ${PADDINGS.MOBILE.RIGHT};

    div.jumbo-content {
      position: relative;
      width: 100%;

      padding-left: ${PADDINGS.MOBILE.LEFT};
      padding-right: ${PADDINGS.MOBILE.RIGHT};

      h1 {
        font-size: 6vw;
      }

      p {
        font-size: 2.5vw;
        /* max-width: 55%; */
        text-align: left;
      }

      button {
        width: auto;
      }
    }

    img {
      position: absolute;
      right: 25px;
      transform: none;
      width: 60vw;
    }
  }

  @media (min-width: ${(props) => props.theme.devices.lg}px) {
    padding-left: ${PADDINGS.MOBILE.LEFT};
    padding-right: ${PADDINGS.MOBILE.RIGHT};

    div.jumbo-content {
      h1 {
        font-size: 10vw;
      }

      p {
        font-size: 1.8vw;
      }

      button {
      }
    }

    img {
      width: 50vw;
    }
  }
`;

export const TechBanner = styled.div`
  padding-left: ${PADDINGS.MOBILE.LEFT};
  padding-right: ${PADDINGS.MOBILE.RIGHT};
  height: 80px;
  background-color: #fbfbfb;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  img {
    height: 25px;

    @media (min-width: ${(props) => props.theme.devices.md}px) {
      height: 35px;
    }
  }
`;

export const ServicesSection = styled.section`
  background-color: #f4f0eb;
  padding-bottom: 36px;

  h1 {
    padding-left: ${PADDINGS.MOBILE.LEFT};
    padding-right: ${PADDINGS.MOBILE.RIGHT};
    font-size: 24px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 36px;
    padding-top: 45px;
    color: black;

    letter-spacing: 0.02em;
    line-height: 1.4;
  }

  .is-services-cards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    padding-left: 4%;
    padding-right: 4%;

    @media (min-width: ${(props) => props.theme.devices.md}px) {
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: stretch;
      padding-left: ${PADDINGS.MOBILE.LEFT};
      padding-right: ${PADDINGS.MOBILE.RIGHT};
    }

    .is-card {
      position: relative;
      min-height: 360px;
      max-width: 100%;
      margin-bottom: 20px;
      box-shadow: none;
      padding: 40px;
      padding-top: 140px;

      @media (min-width: ${(props) => props.theme.devices.md}px) {
        width: 49%;
        margin: 0;
        margin-bottom: 2%;
      }

      & > *:first-child {
        h2 {
          margin: 30px 0 5px 0;
          font-size: 26px;
          font-weight: bold;
          line-height: 1.2;
        }
      }

      & > *:nth-child(2) {
        p {
          font-size: 16px;
          letter-spacing: 0.02em;
          line-height: 1.4;
          margin-bottom: 30px;
          opacity: 0.8;
        }
      }

      & > *:nth-child(3) {
        position: relative;
        z-index: 1;
        button {
          width: auto;

          & > * {
            color: white;
          }

          i {
            margin-left: 7px;
          }
        }
      }

      & > img {
        position: absolute;
        height: 100px;
        top: 40px;
        left: 40px;
      }
    }
  }
`;

export const ContactSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;

  position: relative;
  min-height: 250px;

  width: 100%;
  overflow: hidden;

  .is-landing-map {
    min-height: 250px;
    flex: 1;
    min-width: 400px;
  }

  .is-landing-contact {
    position: relative;
    background-color: white;

    max-width: 600px;
    min-height: 560px;
    width: 100%;

    box-shadow: -125px 0 50px rgba(255, 255, 255, 0.9);
    padding: 20px;

    & > * {
      margin-bottom: 36px;
    }

    & > *:first-child {
      h1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    & > *:nth-child(2) {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: stretch;

      & > * {
        margin-bottom: 12px;

        &:first-child {
          margin-right: 50px;
        }

        h2 {
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 12px;
        }

        p {
          font-size: 16px;
          letter-spacing: 0.02em;
          line-height: 1.4;
        }
      }
    }

    & > *:nth-child(3) {
      h1 {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.2;
        opacity: 0.75;
      }
    }

    & > *:nth-child(4) {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;

      margin-bottom: 12px;

      & > div:first-child {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;

        & > *:first-child {
          margin-right: 5px;
        }
        & > *:last-child {
          margin-left: 5px;
        }
      }

      & > * {
        margin-bottom: 24px;
      }

      textarea {
        height: auto;
      }

      .is-input-wrapper {
        width: 100%;
      }
    }

    & > *:nth-child(5) {
      button {
        width: 150px;
      }

      small {
        display: block;
        margin-top: 12px;
        opacity: 0.7;

        i {
          color: #31d7a2;
          margin-right: 4px;
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  padding-top: 36px;
  padding-bottom: 0px;
  background: black;

  .is-footer-action {
    padding-left: ${PADDINGS.MOBILE.LEFT};
    padding-right: ${PADDINGS.MOBILE.RIGHT};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    & > *:first-child {
      flex: 1;

      h2 {
        margin-bottom: 5px;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.2;
        color: white;
      }

      p {
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 1.4;
        opacity: 0.8;
        color: white;
      }
    }

    & > *:last-child {
      margin-left: 14px;

      button {
        background: black;
        border: 2px solid white;
      }
    }
  }

  .is-footer-menu {
    padding-left: ${PADDINGS.MOBILE.LEFT};
    padding-right: ${PADDINGS.MOBILE.RIGHT};
    padding-top: 36px;
    padding-bottom: 36px;
    background: black;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    & > *:last-child {
      color: white;
      opacity: 0.8;
    }

    & > * {
      margin-top: 20px;
    }

    ul {
      display: flex;

      li {
        opacity: 0.8;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        margin-left: 40px;
        color: white;
        letter-spacing: 0.08em;

        & > * {
          color: white;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`;
