import { GOOGLE_MAP_MOUNT } from '../actionTypes';
const initialState = {
  googleMaps: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_MAP_MOUNT:
      return { ...state, googleMaps: true };
    default:
      return state;
  }
};

export default reducer;
