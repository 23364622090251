// Init App Action Types
export const APP_INIT = 'APP_INIT';
export const GOOGLE_MAP_MOUNT = 'GOOGLE_MAP_MOUNT';

// Auth Action Types
export const AUTH_LOGIN_INIT = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_REQUEST_SIGNOUT = 'AUTH_REQUEST_SIGNOUT';
