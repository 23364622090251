import LayoutContainer from './Container/Container';
import LayoutRow from './Row/Row';
import LayoutCol from './Col/Col';

export const Container = LayoutContainer;
export const Row = LayoutRow;
export const Col = LayoutCol;

export default {
  Container,
  Row,
  Col,
};