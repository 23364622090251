import React from 'react';
import { Route } from 'react-router-dom';
import Card from 'components/Card/Card';
import { Container, Row, Col } from 'components/Layout/Layout';
import { AccSettingsWrapper } from './AccountSettingsPage.style';
import { GeneralSettings } from './General/GeneralSettings';
import { Password } from './Password/Password';
import { Payments } from './Payments/Payments';
import { SettingsSidenav } from './SettingsSidenav/SettingsSidenav';

const AccountSettingsPage = () => {
  return (
    <AccSettingsWrapper className="is-account-settings-container">
      <header
        style={{
          height: '64px',
          backgroundColor: 'white',
          marginBottom: '24px',
        }}
      />

      <Container className="">
        <Row>
          <Col>
            <h2 style={{ marginBottom: '44px', fontWeight: '400' }}>
              Settings
            </h2>
          </Col>
        </Row>

        <Row>
          <Col>
            <SettingsSidenav
              style={{ marginRight: '42px', marginBottom: '20px' }}
            />
          </Col>

          <Col sm={24} md={16}>
            <main>
              <Card style={{ width: '100%' }}>
                <Route
                  exact
                  path={['/settings', '/settings/general']}
                  component={GeneralSettings}
                />

                <Route exact path="/settings/password" component={Password} />

                <Route exact path="/settings/payments" component={Payments} />
              </Card>
            </main>
          </Col>
        </Row>
      </Container>
    </AccSettingsWrapper>
  );
};

export default AccountSettingsPage;
