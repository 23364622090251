import styled from 'styled-components';
import { 
  ToggleContainer as FormCustomToggle,
  ToggleHiddenInput as FormInput,
  ToggleWrapper as FormToggleWrapper,
  ToggleCheckmark as FormCheckmark,
  InputTitle as FormText,
} from '../Form.style';

export const Checkmark = styled(FormCheckmark)`
  &:after {
    content: "";
    position: absolute;
    display: none;

    left: 8px;
    top: 4px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    box-shadow: 2px 1px 7px rgba(0,0,0,.2);
  }
`;

export const Text = styled(FormText)``;

export const CustomToggle = styled(FormCustomToggle)`
  /* border-radius: 5px; */
`;

export const Input = styled(FormInput)``;

export const ToggleWrapper = styled(FormToggleWrapper)``;