import styled from 'styled-components';
import { animated } from 'react-spring';

const PADDINGS = {
  MOBILE: { LEFT: '30px', RIGHT: '30px', TOP: '3em', BOTTOM: '2em' },
  TABLET: { LEFT: '4em', RIGHT: '4em', TOP: '3em', BOTTOM: '2em' },
  DESKTOP: { LEFT: '4em', RIGHT: '4em', TOP: '3em', BOTTOM: '2em' }
};

export const BurgerIcon = styled.div`
  position: relative;
  width: 32px;
  height: 24px;
  cursor: pointer;

  span {
    position: absolute;
    left: 0;
    top: 10px;

    display: block;

    width: 100%;
    height: 2px;

    background-color: black;
  }

  @media (min-width: ${props => props.theme.devices.lg}px) {
    display: none;
  }
`;

export const MobileMenuList = styled(animated.nav)`
  position: absolute;
  top: 100%;
  width: 100%;
  height: calc(100vh - 68px);

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  padding-left: ${PADDINGS.MOBILE.LEFT};
  padding-right: ${PADDINGS.MOBILE.RIGHT};

  background-color: white;

  ul {
    li {
      cursor: pointer;
      padding: 22px 0;
      color: black;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }

  button {
    margin-top: 36px;
    font-size: 15px;
    font-weight: 900;
    line-height: 1.4;
  }
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 68px;
  width: 100%;

  background-color: ${({ isHeaderTop, activeMobileMenu }) =>
    isHeaderTop ? (!activeMobileMenu && 'transparent') || 'white' : 'white'};
  color: white;

  box-shadow: ${({ isHeaderTop }) =>
    isHeaderTop ? 'none' : '0px -9px 10px rgba(0,0,0,1)'};

  transition: all 0.2s;

  .is-header {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    padding-left: ${PADDINGS.MOBILE.LEFT};
    padding-right: ${PADDINGS.MOBILE.RIGHT};
    svg {
      height: 32px;
      width: 27px;
    }

    .is-header-logo {
    }

    .is-burger-menu {
      position: relative;
      width: 32px;
      height: 24px;
      cursor: pointer;

      &:after,
      &:before {
        content: '';

        position: absolute;
        left: 0;
        top: 10px;

        width: 100%;
        height: 2px;

        background-color: ${({ activeMobileMenu }) =>
          activeMobileMenu ? 'black' : 'black'};

        transform-origin: center center;
        transition: all 0.3s;
      }

      &:after {
        transform: ${props =>
          props.activeMobileMenu ? 'rotate(45deg)' : 'translateY(-4.5px)'};
      }

      &:before {
        transform: ${props =>
          props.activeMobileMenu ? 'rotate(-45deg)' : 'translateY(4.5px)'};
      }
    }

    ul {
      display: none;

      @media (min-width: ${props => props.theme.devices.lg}px) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        li {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.4;
          margin-left: 40px;
          color: black;
          letter-spacing: 0.08em;
          cursor: pointer;

          & > * {
            color: black;
          }

          &:hover {
            opacity: 0.9;
          }
        }

        & > li:last-child {
          margin-left: 70px;
        }

        button {
          font-weight: bold;
          color: white;
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.devices.lg}px) {
    .is-header {
      .is-burger-menu {
        display: none;
      }
    }
  }
`;
