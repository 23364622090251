import React from 'react';
import {
  InputWrapper,
  InputLabel,
  InputTagWrapper,
  InputDescriptionText,
  InputWithStyles,
} from './Input.style.js';

const Input = React.forwardRef(
  (
    {
      label: { text, ...labelProps } = {},
      status = null,
      inputDescription = null,
      className = '',
      ...props
    },
    ref
  ) => {
    return (
      <InputWrapper className="is-input-container">
        {text && (
          <InputLabel className="is-input-label" {...labelProps}>
            {text}
          </InputLabel>
        )}

        <InputTagWrapper className="is-input-wrapper" status={status}>
          <InputWithStyles
            className={`is-input ${className}`}
            type={props.type || 'text'}
            ref={ref}
            {...props}
          />
        </InputTagWrapper>

        {inputDescription && (
          <InputDescriptionText
            className="is-input-description"
            status={status}
          >
            {inputDescription}
          </InputDescriptionText>
        )}
      </InputWrapper>
    );
  }
);

export default Input;
