import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import { Input } from '../../../../components/Form/Form';
import Button from '../../../../components/Button/Button';

import {
  handleResetPassword,
  verifyPasswordResetCode,
} from '../../../../api/auth/index';

const formInitialValues = {
  password: '',
};

const validateLogin = values => {
  return {};
};

const CHECKING = 'CHECKING';
const VERIFIED = 'VERIFIED';
const ERROR = 'ERROR';
const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

const ResetPasswordForm = ({ onSubmit }) => {
  const [passwordCodeState, changePasswordCodeState] = useState(CHECKING);
  const [actionCode, setActionCode] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);
    const actionCodeParam = url.searchParams.get('oobCode');

    setActionCode(actionCodeParam);

    verifyPasswordResetCode({ actionCode: actionCodeParam })
      .then(res => {
        changePasswordCodeState(VERIFIED);
      })
      .catch(err => {
        changePasswordCodeState(ERROR);
      });
  }, []);

  if (passwordCodeState === CHECKING) {
    return <p style={{ textAlign: 'center' }}>Please wait...</p>;
  }

  if (passwordCodeState === ERROR) {
    return (
      <React.Fragment>
        <p style={{ textAlign: 'center' }}>This link has expired.</p>
        <Link
          to="/login"
          style={{
            display: 'block',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '0.9em',
            marginBottom: '0.25em',
            marginTop: '1em',
            opacity: '0.65',
          }}
        >
          Login
        </Link>
      </React.Fragment>
    );
  }

  if (passwordCodeState === PASSWORD_CHANGED) {
    return (
      <React.Fragment>
        <p style={{ textAlign: 'center' }}>Your password has been changed</p>
        <Link
          to="/login"
          style={{
            display: 'block',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '0.9em',
            marginBottom: '0.25em',
            opacity: '0.65',
          }}
        >
          Login
        </Link>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h2
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '1em',
          fontSize: '30px',
        }}
      >
        Change your password
      </h2>
      <p
        style={{
          textAlign: 'center',
          marginBottom: '2em',
          fontSize: '0.9em',
          opacity: '0.65',
          lineHeight: '1.4',
        }}
      >
        Please enter your new password.
      </p>

      <Formik
        initialValues={formInitialValues}
        validate={validateLogin}
        onSubmit={({ password }, { setSubmitting, setFieldError }) => {
          handleResetPassword({ actionCode, newPassword: password })
            .then(res => {
              changePasswordCodeState(PASSWORD_CHANGED);
              setSubmitting(false);
            })
            .catch(err => {
              if (err.message) setFieldError('password', err.message);
              setSubmitting(false);
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          ...formikProps
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Input
                type="password"
                name="password"
                placeholder="New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                status={touched.password && errors.password && 'error'}
                inputDescription={touched.password && errors.password}
              />
              <br />
              <br />

              <Button style={{ padding: '1.4em' }} type="submit">
                CHANGE PASSWORD
              </Button>
            </form>
          );
        }}
      </Formik>
      <br />

      <Link
        to="/login"
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '0.9em',
          marginBottom: '0.25em',
          opacity: '0.65',
        }}
      >
        Login
      </Link>
    </React.Fragment>
  );
};

export default ResetPasswordForm;
