import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { LandingPage } from './landing-page/LandingPage';
import { NewProjectWizard } from './features/NewProjectWizard/NewProjectWizard';
import LogInPage from './features/Authentication/LogIn/LogInPage/LogInPage';
import SignUpPage from './features/Authentication/SignUp/SignUpPage/SignUpPage';
import ResetPasswordPage from './features/Authentication/ResetPassword/ResetPasswordPage/ResetPasswordPage';
import ActionPage from './features/Authentication/Action/ActionPage/ActionPage';
import PrivateRoute from './features/Authentication/PrivateRoute/PrivateRoute';
import AccountSettingsPage from './features/AccountSettings/AccountSettingsPage';

import { signOutRequest } from './state/auth/actions';

import 'rc-slider/assets/index.css';
import theme from './theme';
import GlobalStyle from './globalStyles';

library.add(fas);

const App = ({ appInit, authenticated, signOutRequest }) => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          {appInit && (
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/new-project" component={NewProjectWizard} />
              <Route path="/logIn" component={LogInPage} />
              <Route path="/signup" component={SignUpPage} />
              <Route path="/forgot-password" component={ResetPasswordPage} />
              <Route path="/auth/action" component={ActionPage} />

              <PrivateRoute path="/settings" component={AccountSettingsPage} />
              <Route render={() => <h1>404 Page not found.</h1>} />
            </Switch>
          )}
          <GlobalStyle />
        </React.Fragment>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ appInit, auth: { authenticated } }) => ({
  appInit,
  authenticated
});

export default connect(
  mapStateToProps,
  { signOutRequest }
)(App);

/*
  {authenticated && (
  <div>
    <Link to="/settings">Settings</Link>
    <button onClick={signOutRequest}>Sign out</button>
  </div>
)}
{!authenticated && <Link to="/login">Log in</Link>}
  */
