import {
  AUTH_LOGIN_INIT,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_SIGNOUT,
  AUTH_REQUEST_SIGNOUT,
} from '../actionTypes';

export const login = () => ({
  type: AUTH_LOGIN_INIT,
});

export const loginSuccess = payload => ({
  type: AUTH_LOGIN_SUCCESS,
  payload,
});

export const loginError = payload => ({
  type: AUTH_LOGIN_ERROR,
  payload,
});

export const signOutRequest = () => {
  return {
    type: AUTH_REQUEST_SIGNOUT,
  };
};

export const signOut = () => {
  return {
    type: AUTH_SIGNOUT,
  };
};
