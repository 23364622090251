import styled, { css } from 'styled-components';
import { InputTitle } from '../Form.style';

export const InputWithStyles = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-size: 16px;
`;

export const InputWrapper = styled.div`
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
  margin: 0;
`;

export const InputLabel = styled(InputTitle)`
  font-size: 12px;
  margin: 0 0 0.5em 0;
`;

export const InputTagWrapper = styled.div`
  position: relative;

  & > ${InputWithStyles} {
    width: 100%;
    height: 42px;
    padding: 1em 2em 1em 1em;

    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.inputBorderColor};
    border-radius: ${props => props.theme.inputsBorderRadius};

    background-color: ${props => props.theme.white};

    color: ${props => props.theme.secondaryFontColor};
    font-weight: 600;
    font-size: 16px;
    outline: none;
    box-shadow: ${props => props.theme.inputBoxShadow};
    transition: 0.35s;

    &:focus {
      border-color: ${props => props.theme.inputOutlineDefault};
    }

    &:hover {
      border-color: ${props => props.theme.inputOutlineDefault};
    }

    &::placeholder {
      color: ${props => props.theme.inputPlaceholderColor};
    }
  }

  ${statusStyles}
`;

export const InputDescriptionText = styled.small`
  /* position: absolute; */
  top: 100%;

  margin-top: 0.5em;
  width: 100%;

  color: ${props => props.theme.secondaryFontColor};
  font-weight: 600;
  font-size: ${props => props.theme.inputSmallFontSize};

  white-space: normal;
  word-break: break-all;

  ${statusDescriptionStyle}
`;

function statusDescriptionStyle(props) {
  if (props.status === 'error') {
    return css`
      color: ${props => props.theme.errorColor} !important;
    `;
  }

  if (props.status === 'success') {
    return css`
      color: ${props => props.theme.successColor} !important;
    `;
  }
}

function statusStyles(props) {
  if (props.status === 'error') {
    return css`
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        background: ${props => props.theme.inputOutlineError};
        border-radius: 50%;
        transform: translateY(-50%);
        top: 50%;
        right: 1em;
      }

      & > ${InputWithStyles} {
        border-color: ${props => props.theme.inputOutlineError};
      }
    `;
  }

  if (props.status === 'success') {
    return css`
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        background: ${props => props.theme.inputOutlineSuccess};
        border-radius: 50%;
        transform: translateY(-50%);
        top: 50%;
        right: 1em;
      }

      & > ${InputWithStyles} {
        border-color: ${props => props.theme.inputOutlineSuccess};
      }
    `;
  }
}
