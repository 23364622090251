import firebase from '../firebaseConfig';

const auth = firebase.auth();

export const signUp = ({ email, password }) => {
  return auth.createUserWithEmailAndPassword(email, password).then(() => {
    sendVerificationEmail();
  });
};

export const logIn = ({ email, password }) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return auth.signOut();
};

export const resetPassword = ({ email }) => {
  return auth.sendPasswordResetEmail(email);
};

export const sendVerificationEmail = () => {
  return auth.currentUser.sendEmailVerification();
};

export const handleVerifyEmail = ({ actionCode }) => {
  return auth.applyActionCode(actionCode);
};

export const verifyPasswordResetCode = ({ actionCode }) => {
  // Verify the password reset code is valid.
  return auth.verifyPasswordResetCode(actionCode);
};

export const handleResetPassword = ({ actionCode, newPassword }) => {
  return auth.confirmPasswordReset(actionCode, newPassword);
};

export default {
  signUp,
  logIn,
  signOut,
  resetPassword,
  sendVerificationEmail,
  handleVerifyEmail,
};
