import React from 'react';
import { NavLink } from 'react-router-dom';
import { Sidenav } from 'components/Sidenav/Sidenav';

const PAGES_CONFIG = [
  { value: 'General', url: '/settings/general' },
  { value: 'Password', url: '/settings/password' },
  { value: 'Payments', url: '/settings/payments' },
];

export const SettingsSidenav = props => {
  return (
    <Sidenav {...props}>
      {PAGES_CONFIG.map(({ value, url }) => (
        <NavLink to={url} key={value} activeClassName="active-link">
          {value}
        </NavLink>
      ))}
    </Sidenav>
  );
};
