import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../../../components/Layout/Layout';
import AuthenticationLayout from '../../AuthenticationLayout/AuthenticationLayout';
import Card from '../../../../components/Card/Card';
import LogInForm from '../LogInForm/LogInForm';

const LogInPage = () => {
  return (
    <AuthenticationLayout>
      <Row justifyContent="center" alignItems="center">
        <Col sm={24} md={18} lg={14}>
          <Card style={{boxShadow: 'none'}}>
            <h2 style={{fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', fontSize: '30px'}}>Welcome back.</h2>
            <p style={{textAlign: 'center', marginBottom: '2em', fontSize: '0.9em', opacity: '0.65'}}>New to BinaryLeap? <strong><Link to="/signup">Sign up</Link></strong></p>
            <LogInForm />
            <br />
            <Link to="/forgot-password" style={{fontWeight: 'bold', fontSize: '0.9em', marginBottom: '0.25em', opacity: '0.65'}}>Forgot password?</Link>
          </Card>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default LogInPage;