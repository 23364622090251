import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../../../components/Layout/Layout';
import AuthenticationLayout from '../../AuthenticationLayout/AuthenticationLayout';
import Card from '../../../../components/Card/Card';
import SignUpForm from '../SignUpForm/SignUpForm';

const SignUpPage = () => {
  return (
    <AuthenticationLayout>
      <Row justifyContent="center" alignItems="center">
        <Col sm={24} md={18} lg={14}>
          <Card style={{boxShadow: 'none'}}>
            <h2 style={{fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', fontSize: '30px'}}>Sign Up</h2>
            <p style={{textAlign: 'center', marginBottom: '1.5em', fontSize: '0.9em', opacity: '0.65'}}>Already signed up? <strong><Link to="/login">Log in</Link></strong></p>
            <SignUpForm />
          </Card>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default SignUpPage;