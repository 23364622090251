import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../../../components/Layout/Layout';
import AuthenticationLayout from '../../AuthenticationLayout/AuthenticationLayout';
import Card from '../../../../components/Card/Card';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import VerifyEmail from '../VerifyEmail/VerifyEmail';

const ActionPage = () => {
  const [mode, changeMode] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const newMode = url.searchParams.get('mode');

    if (newMode) changeMode(newMode);
  }, []);

  return (
    <AuthenticationLayout>
      <Row justifyContent="center" alignItems="center">
        <Col sm={24} md={18} lg={14}>
          <Card style={{ boxShadow: 'none' }}>
            {mode === 'resetPassword' && <ResetPasswordForm />}

            {mode === 'verifyEmail' && (
              <React.Fragment>
                <h2
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: '1em',
                    fontSize: '30px',
                  }}
                >
                  Verify your email
                </h2>
                <VerifyEmail />
                <Link
                  to="/login"
                  style={{
                    display: 'block',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '0.9em',
                    marginBottom: '0.25em',
                    marginTop: '1em',
                    opacity: '0.65',
                  }}
                >
                  Back to Login
                </Link>
              </React.Fragment>
            )}
          </Card>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ActionPage;
