import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: white;
  border-radius: ${props => props.theme.defaultBorderRadius};
  box-shadow: 0 20px 30px rgba(0,0,0,0.1);
  width: 98%;
  margin: auto auto;
  max-width: 720px;
  padding: 1em;
`;