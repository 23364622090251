import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../../../components/Layout/Layout';
import AuthenticationLayout from '../../AuthenticationLayout/AuthenticationLayout';
import Card from '../../../../components/Card/Card';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <AuthenticationLayout>
      <Row justifyContent="center" alignItems="center">
        <Col sm={24} md={18} lg={14}>
          <Card style={{ boxShadow: 'none' }}>
            <ResetPasswordForm />
            <br />
            <Link
              to="/login"
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '0.9em',
                marginBottom: '0.25em',
                opacity: '0.65',
              }}
            >
              Back to Login
            </Link>
          </Card>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ResetPasswordPage;
