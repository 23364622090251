import React from 'react';
import { RowWithStyles } from './Row.style.js'

const Row = ({ children, ...props }) => {
  
  return (
    <RowWithStyles {...props} >{children}</RowWithStyles>
  );
};

export default Row;