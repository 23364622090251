import styled from 'styled-components';
import { 
  ToggleContainer as FormCustomToggle,
  ToggleHiddenInput as FormInput,
  ToggleWrapper as FormToggleWrapper,
  ToggleCheckmark as FormCheckmark,
  InputTitle as FormText,
} from '../Form.style';

export const Checkmark = styled(FormCheckmark)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translate(0%, -50%);

    width: 18px;
    height: 18px;
    background-color: ${props => props.theme.grey};

    /* border-radius: 10px; */
    transition: .35s;
  }
  
`;

export const Text = styled(FormText)``;

export const CustomToggle = styled(FormCustomToggle)`
  position: relative;

  width: 48px;
  height: 24px;
  
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.inputBorderColor};
  /* border-radius: 12px; */
  box-shadow: ${props => props.theme.inputBoxShadow};
`;

export const Input = styled(FormInput)`
  &:checked ~ ${CustomToggle} > ${Checkmark}:after {
    left: calc(100% - 14px);
    background-color: ${props => props.theme.white};
    width: 7px;
    height: 16px;
  }
`;

export const ToggleWrapper = styled(FormToggleWrapper)``;

