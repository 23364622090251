import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSprings, useSpring, animated, config } from 'react-spring';
import { Formik } from 'formik';

import { createNewContact } from '../api/contact';

// custom components
import { Navbar } from 'components/Navbar/Navbar';
import Button from '../components/Button/Button';
import Card from '../components/Card/Card';
import { Input, Textarea } from '../components/Form/Form';

import reactIllustationSvg from './images/react_illustration.svg';
import graphIllustationSvg from './images/graph_illustration.svg';
import mvpIllustrationSvg from './images/mvp.svg';
import frontEndIllustrationSvg from './images/front_end_dev.svg';

// styles
import {
  LandingPageSection,
  Jumbotron,
  ServicesSection,
  Footer,
  ContactSection
} from './LandingPage.style';
import { googleMapStyles } from './googleMapStyles';

const INITIAL_VALUES = {
  name: '',
  email: '',
  message: ''
};

const validation = (values) => {
  const errors = {};

  if (!values.name) errors.name = 'Name is required';

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Email is invalid';
  }

  if (!values.message) errors.message = 'Project description is required';

  return errors;

  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
};

export const LandingPage = ({ history }) => {
  const googleMapRef = useRef();
  const [submittedForm, setSubmitedForm] = useState(false);
  const [yProps, setY] = useSpring(() => ({
    immediate: false,

    y: window.scrollY,

    config: { ...config.stiff, clamp: true },

    onFrame: (props) => {
      window.scroll(0, props.y);
    }
  }));

  const homeRef = useRef();
  const servicesRef = useRef();
  const contactRef = useRef();

  const handleNavLinkClick = (navItem) => {
    if (navItem === 'startProject') {
      history.push('/new-project');
      return;
    } // end if

    const map = { home: homeRef, services: servicesRef, contact: contactRef };
    const y =
      map[navItem].current.getBoundingClientRect().top + window.scrollY - 68;

    // hack to make scrollto work as expected.
    yProps.y.setValue(window.scrollY);
    setY({ y: window.scrollY });
    setY({ y });
  };

  useEffect(() => {
    if (window.google) {
      const map = new window.google.maps.Map(googleMapRef.current, {
        center: { lat: 53.349182, lng: -6.247741 },
        zoom: 16,
        disableDefaultUI: true,
        gestureHandling: 'none',
        zoomControl: false,
        styles: googleMapStyles
      });

      new window.google.maps.Marker({
        position: new window.google.maps.LatLng(53.349182, -6.247741),
        map: map
      });
    }
  }, []);

  const [jumboSpring] = useSprings(3, (i) => {
    return {
      config: config.stiff,

      from: {
        transform: `translateY(${5 + i * 7}px)`
      },

      transform: 'translateY(0px)'
    };
  });

  const navItems = [
    { key: 'home', value: 'Home' },
    { key: 'services', value: 'Services' },
    { key: 'contact', value: 'Contact' },
    { key: 'startProject', isBtn: true, value: 'Start A Project' }
  ];

  return (
    <LandingPageSection>
      <Navbar navItems={navItems} onNavClick={handleNavLinkClick} />
      <Jumbotron ref={homeRef}>
        <div className="jumbo-content">
          {jumboSpring.map((props, i) => {
            if (i === 0) {
              return (
                <animated.h1 key={i} style={props}>
                  Front-end software <br /> development <br /> house.
                </animated.h1>
              );
            }

            if (i === 1) {
              return (
                <animated.p key={i} style={props}>
                  A creative agency specializing in building scalable, high
                  performance
                  <br />
                  responsive web applications.
                </animated.p>
              );
            }

            if (i === 2) {
              return (
                <animated.div key={i} style={props}>
                  <Link to="/new-project">
                    <Button
                      style={{
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        lineHeight: 1.4
                      }}
                    >
                      Start A Project
                    </Button>
                  </Link>
                </animated.div>
              );
            }

            return null;
          })}
        </div>
      </Jumbotron>

      <ServicesSection
        ref={servicesRef}
        style={{ backgroundColor: '#F6F9FC', color: 'black' }}
      >
        <h1>How can we help you</h1>

        <div className="is-services-cards">
          <Card>
            <div>
              <h2>Front-end Development</h2>
            </div>
            <div>
              <p>
                Whether you’re looking for an external development partner, or
                expert developers to integrate with your in-house team, we’ll
                provide the right talent for your project.
              </p>
            </div>
            <div>
              <Link to="/new-project">
                <Button>Start A Project</Button>
              </Link>
            </div>
            <img src={frontEndIllustrationSvg} alt="react logo" />
          </Card>

          <Card>
            <div>
              <h2>React Development</h2>
            </div>
            <div>
              <p>
                Let’s create outstanding and interactive frontend with React!
                With our tech expertise and your idea, we are the perfect match
                to build a great and unique experience for the final users.
              </p>
            </div>
            <div>
              <Link to="/new-project">
                <Button>Start A Project</Button>
              </Link>
            </div>
            <img src={reactIllustationSvg} alt="react logo" />
          </Card>

          <Card>
            <div>
              <h2>MVP</h2>
            </div>
            <div>
              <p>
                Do you have an amazing idea for an app or software but don’t
                know where to start?
              </p>
            </div>
            <div>
              <Link to="/new-project">
                <Button>Start A Project</Button>
              </Link>
            </div>
            <img src={mvpIllustrationSvg} alt="react logo" />
          </Card>

          <Card>
            <div>
              <h2>Front-end Visualizations</h2>
            </div>
            <div>
              <p>
                We help you build beautiful, useful visualizations. Using the
                state-of-the-art D3.js library we will bring your data to life.
              </p>
            </div>
            <div>
              <Link to="/new-project">
                <Button>Start A Project</Button>
              </Link>
            </div>
            <img src={graphIllustationSvg} alt="react logo" />
          </Card>
        </div>
      </ServicesSection>

      <ContactSection ref={contactRef}>
        <div className="is-landing-map" ref={googleMapRef}></div>
        <div className="is-landing-contact">
          <div>
            <h1>Get in touch</h1>
          </div>

          <div>
            <div>
              <h2>Address</h2>
              <p>
                Dogpatch Labs, <br />
                The CHQ Building, <br />
                Custom House Quay, <br />
                Dublin 1, D01 Y6H7 <br />
                Ireland
              </p>
            </div>
            <div>
              <h2>Contact</h2>
              <p>info@binaryleap.ie</p>
            </div>
          </div>

          <div>
            <h1>Have a question?</h1>
          </div>

          <Formik
            initialValues={INITIAL_VALUES}
            validate={validation}
            onSubmit={(onSubmitProps, props) => {
              createNewContact(onSubmitProps)
                .then((res) => {
                  setSubmitedForm(true);
                  props.resetForm();
                })
                .catch((err) => {
                  setSubmitedForm(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <React.Fragment>
                  <div>
                    <div>
                      <Input
                        placeholder="Full name"
                        type="text"
                        name="name"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.name}
                        status={
                          formikProps.touched.name &&
                          formikProps.errors.name &&
                          'error'
                        }
                        inputDescription={
                          formikProps.touched.name && formikProps.errors.name
                        }
                      />

                      <Input
                        placeholder="Email address"
                        type="text"
                        name="email"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.email}
                        status={
                          formikProps.touched.email &&
                          formikProps.errors.email &&
                          'error'
                        }
                        inputDescription={
                          formikProps.touched.email && formikProps.errors.email
                        }
                      />
                    </div>
                    <Textarea
                      placeholder="Message"
                      rows={4}
                      type="text"
                      name="message"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.message}
                      status={
                        formikProps.touched.message &&
                        formikProps.errors.message &&
                        'error'
                      }
                      inputDescription={
                        formikProps.touched.message &&
                        formikProps.errors.message
                      }
                    />
                  </div>
                  <div>
                    <Button
                      onClick={formikProps.handleSubmit}
                      disabled={formikProps.isSubmitting}
                    >
                      Send
                    </Button>
                    {submittedForm && (
                      <small>
                        <i className="fas fa-check-circle"></i>
                        Thank you for reaching out. We'll get back to you
                        shortly.
                      </small>
                    )}
                  </div>
                </React.Fragment>
              );
            }}
          </Formik>
        </div>
      </ContactSection>

      <Footer>
        <div className="is-footer-action">
          <div>
            <h2>Ready to get started?</h2>
            <p>Feel free to reach out with any questions you have.</p>
          </div>

          <div>
            <Link to="/new-project">
              <Button>Start A Project</Button>
            </Link>
          </div>
        </div>

        <div className="is-footer-menu">
          <div>
            <ul>
              <li>Home</li>
              <li>Services</li>
              <li>
                <a href="mailto:info@binaryleap.ie">Contact Us</a>
              </li>
            </ul>
          </div>
          <div>&copy; 2019 Binary Leap Ltd.</div>
        </div>
      </Footer>
    </LandingPageSection>
  );
};

export default LandingPage;
