import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';

import { Input } from '../../../../components/Form/Form';
import Button from '../../../../components/Button/Button';

import { logIn } from '../../../../api/auth/index';

const formInitialValues = {
  email: '',
  password: '',
};

const validateLogin = values => {
  return {};
};

const LogInForm = ({ onSubmit, history }) => {
  return (
    <Formik
      initialValues={formInitialValues}
      validate={validateLogin}
      onSubmit={({ email, password }, { setSubmitting, setStatus }) => {
        logIn({ email, password })
          .then(res => {
            setSubmitting(false);
            history.push('/');
          })
          .catch(err => {
            setStatus({
              error:
                'The email address and password you entered are incorrect.',
            });

            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        ...formikProps
      }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              status={touched.email && errors.email && 'error'}
              inputDescription={touched.email && errors.email}
              required
            />
            <br />

            <Input
              autoComplete="new-password"
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              status={touched.password && errors.password && 'error'}
              inputDescription={touched.password && errors.password}
              required
            />

            <br />
            <br />

            {status && status.error && (
              <p className="form-error-label">{status.error}</p>
            )}

            <Button style={{ padding: '1.4em' }} type="submit">
              LOG IN
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default withRouter(LogInForm);
