import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

/*
  state
    - isChecked
    - 
  props
    - checked
    - renderIcon
    - children

*/
const CardCheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  max-height: 200px;
  max-width: 475px;
  min-width: 200px;

  padding: 25px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 22px;

  background: #f8f8f9;
  background: #fff;
  border: 1px solid rgba(28, 31, 62, 0.09);
  box-shadow: 0 6px 3px rgba(12, 9, 46, 0.05);
  border-radius: 4px;
  transition: all 0.1s ease;
  cursor: pointer;

  .is-card-checkbox-tick {
  }

  .is-card-checkbox-icon {
    max-height: 65px;
    padding-bottom: 18px;

    & > * {
      height: 100%;
    }

    & > i {
      font-size: 50px;
      color: #505469;
    }
  }

  .is-card-checkbox-label {
    font-weight: 800;
    font-size: 17px;
    color: #505469;
    letter-spacing: -0.5px;
    display: block;
    width: 100%;
    text-align: center;
  }

  ${({ active }) => {
    if (active) {
      return css`
        background: black;
        /* border: 1px solid #c1c6ec; */
        box-shadow: 0 6px 3px rgba(0, 0, 0, 0.2);

        .is-card-checkbox-label {
          color: white;
        }
      `;
    }
  }}

  ${({ withIcon }) => {
    if (!withIcon) {
      return css`
        padding: 22px 10px 22px 61px;
        max-height: 68px;

        .is-card-checkbox-label {
          text-align: left;
        }
      `;
    }
  }}
`;

const Tickmark = styled.i`
  position: absolute;
  display: none;
  top: 8px;
  right: 8px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.18), 0 2px 2px rgba(0, 0, 0, 0.16);

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  ${({ withIcon }) => {
    if (!withIcon) {
      return css`
        display: block;
        top: 50%;
        left: 8px;
        right: auto;
        transform: translateY(-50%);

        padding-right: 7px;

        &:before {
          color: rgba(0, 0, 0, 0.16);
        }
      `;
    }
  }}

  ${({ active }) => {
    if (active) {
      return css`
        display: block;
        background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
        &:before {
          color: black;
        }
      `;
    }
  }}
`;
export const CardCheckbox = ({
  checked = false,
  onChange,
  label,
  renderIcon = null,
}) => {
  const [isChecked, updateCheckbox] = useState(checked);

  useEffect(() => {
    updateCheckbox(checked);
  }, [checked]);

  return (
    <CardCheckboxContainer
      onClick={() => onChange(!isChecked)}
      active={isChecked}
      withIcon={renderIcon}
    >
      <Tickmark
        className="fas fa-check is-card-checkbox-tick"
        active={isChecked}
        withIcon={renderIcon}
      />

      {renderIcon && (
        <div className="is-card-checkbox-icon">{renderIcon()}</div>
      )}

      <span className="is-card-checkbox-label">{label}</span>
    </CardCheckboxContainer>
  );
};

export default CardCheckbox;
