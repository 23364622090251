import styled from 'styled-components';
import { 
  ToggleContainer as FormCustomToggle,
  ToggleHiddenInput as FormInput,
  ToggleWrapper as FormToggleWrapper,
  ToggleCheckmark as FormCheckmark,
  InputTitle as FormText,
} from '../Form.style';

export const Checkmark = styled(FormCheckmark)`
  &:after {
    content: "";
    position: absolute;
    display: none;
    
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 7px rgba(0,0,0,.2);
  }
  
`;

export const Text = styled(FormText)``;

export const CustomToggle = styled(FormCustomToggle)`
  border-radius: 50%;
`;

export const Input = styled(FormInput)``;

export const ToggleWrapper = styled(FormToggleWrapper)``;