import { APP_INIT } from '../actionTypes';
const initialState = false;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_INIT:
      return true;
    default:
      return state;
  }
};

export default reducer;
